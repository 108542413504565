import React from "react";
import Typography from "@material-ui/core/Typography";
import { Button, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
   content: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: "10px",
      padding: "10px",
   },
}));

export default function Finish({ values,setStep }) {
   const classes = useStyles();


   return (
      <React.Fragment>
         <Grid
            container
            spacing={3}
            alignItems="center"
            justifyContent="center"
            direction="column"
         >
            <Grid item xs={6}>
               <Typography variant="h6" align="center">
                  Thank you for booking your tee time!
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant="subtitle1" align="center">
                  Your booking details are below.
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Typography variant="subtitle1" align="center">
                  Confirmation #{values.confirmation}. We have text your
                  reservation confirmation.
               </Typography>
            </Grid>
            <Grid item xs={6}>
               <Button variant="contained" color="primary" onClick={()=>setStep(0)}>
                  Book Another Tee Time
               </Button>
            </Grid>
         </Grid>
         
      </React.Fragment>
   );
}
