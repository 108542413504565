import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import moment from 'moment';
import { formatMoney } from '../utils';
import {
   Divider,
   FormControl,
   FormHelperText,
   InputLabel,
   MenuItem,
   NativeSelect,
   Select,
} from '@material-ui/core';
import PlayerDialog from './PlayerDialog';

const TeetimeCard = ({ teetime, onBook }) => {
   const [players, setPlayers] = React.useState(0);
   const [open, setOpen] = React.useState(false);
   const [value, setValue] = React.useState('');
   const useStyles = makeStyles((theme) => ({
      root: {
         minWidth: 120,
      },
      content: {
         display: 'flex',
         backgroundColor: '#e6f2ff',
         flexDirection: 'column',
         alignItems: 'center',
         justifyContent: 'center',
      },
      divider: {
         width: '100%',
         marginBottom: theme.spacing(2),
      },
      bullet: {
         display: 'inline-block',
         margin: '0 2px',
         transform: 'scale(0.8)',
      },
      title: {
         fontSize: 14,
         marginTop: theme.spacing(0),
      },
      pos: {
         marginBottom: 0,
      },
      formControl: {
         margin: theme.spacing(1),
         minWidth: 120,
      },
      selectEmpty: {
         marginTop: theme.spacing(0),
      },
      btn: {
         color: 'blue',
      },
      players: {
         width: '90%',
         marginBottom: theme.spacing(1),
      },
   }));

   const classes = useStyles();
   let playerFields = { text: 'players', value: 'id' };
   let playerList = [
      { id: 1, players: '1 player' },
      { id: 2, players: '2 players' },
      { id: 3, players: '3 players' },
      { id: 4, players: '4 players' },
   ];
   playerList = playerList.slice(0, teetime?.players);

   function handleClose(val) {
      if (val) {
         onBook(teetime, val);
      }
      setOpen(false);
   }

   function handleBook() {
      if (players <= 0) {
         setOpen(true);
      }else{
         onBook(teetime, players);
      }
   }


   return (
      <>
      <Card className={classes.root}>
         <CardContent className={classes.content}>
            <Typography
               className={classes.title}
               color='textPrimary'
               gutterBottom
            >
               {moment(teetime?.tee_time, ['HH:mm']).format('h:mm A')}{' '}
            </Typography>
            <Divider className={classes.divider} />
            <Typography variant='h5' component='p'>
               {formatMoney(teetime?.cart_fee_18 + teetime?.green_fee_18)}
            </Typography>
            <div className={classes.players}>
               <DropDownListComponent
                  id='players'
                  dataSource={playerList}
                  fields={playerFields}
                  change={(e) => setPlayers(e.value)}
                  placeholder='# of players'
                  value={players}
                  popupHeight='220px'
                  style={{ width: '30'}}
               />
            </div>
            <Button
               size='small'
               className={classes.btn}
               onClick={handleBook}
            >
               Book
            </Button>
         </CardContent>
         <CardActions className={classes.content}>
            <Divider className={classes.divider} />
            <Typography variant='body2' component='p'>
               {teetime?.availability === 1 && <div>1 player</div>}
               {teetime?.availability >= 2 && (
                  <div>1-{teetime?.availability} players</div>
               )}
            </Typography>
         </CardActions>
      </Card>
      <PlayerDialog
          classes={{
            paper: classes.paper,
          }}
          id="players-dialog"
          keepMounted
          open={open}
          onClose={handleClose}
          value={value}
          players = {teetime?.players}
        />
      </>
   );
};

export default TeetimeCard;
