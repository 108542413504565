import {
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControlLabel,
   Radio,
   RadioGroup,
} from '@material-ui/core';
import React from 'react';



const PlayerDialog = (props) => {
   const { onClose, value: valueProp, open,players, ...other } = props;
   const [value, setValue] = React.useState(valueProp);
   const radioGroupRef = React.useRef(null);
   let  options = [
    '1',
    '2',
    '3',
    '4',
    ];
    options = options.slice(0, players);
   React.useEffect(() => {
      if (!open) {
         setValue(valueProp);
      }
   }, [valueProp, open]);

   const handleEntering = () => {
      if (radioGroupRef.current != null) {
         radioGroupRef.current.focus();
      }
   };

   const handleCancel = () => {
      onClose();
   };

   const handleOk = () => {
      onClose(value);
   };

   const handleChange = (event) => {
      setValue(event.target.value);
   };

   return (
      <Dialog
         maxWidth='xs'
         onEntering={handleEntering}
         aria-labelledby='confirmation-dialog-players'
         open={open}
         {...other}
      >
         <DialogTitle id='confirmation-dialog-title'>
            Please select the number of players
         </DialogTitle>
         <DialogContent dividers>
            <RadioGroup
               ref={radioGroupRef}
               aria-label='number of players'
               name='numOfPlayers'
               value={value}
               onChange={handleChange}
            >
               {options.map((option) => (
                  <FormControlLabel
                     value={option}
                     key={option}
                     control={<Radio />}
                     label={option}
                  />
               ))}
            </RadioGroup>
         </DialogContent>
         <DialogActions>
            <Button autoFocus onClick={handleCancel} color='primary'>
               Cancel
            </Button>
            <Button onClick={handleOk} color='primary'>
               Ok
            </Button>
         </DialogActions>
      </Dialog>
   );
};

export default PlayerDialog;
