import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import styles from './styles';
import moment from 'moment';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
   MuiPickersUtilsProvider,
   KeyboardDatePicker,
} from '@material-ui/pickers';

import { CircularProgress, Fade, Slide } from '@material-ui/core';

import axios from 'axios';
import TeetimeCard from './TeetimeCard';
import { useTeetimeInventory } from '../hooks/useTeetimeInventory';
import { useQueryClient } from 'react-query';

export default function Search({
   nextStep,
   values,
   handleFormData,
   handleStateUpdate,
}) {
   const queryClient = useQueryClient();
   const [selectedDate, setSelectedDate] = useState(new Date());
   const [searched, setSearched] = useState(false);

   const [showTeetime, setShowTeetime] = useState(true);

   const [error, setError] = useState(null);
   const [selectedID, setSelectedID] = useState(null);
   const { isLoading, data } = useTeetimeInventory(selectedDate, 6, 19, 1);

   
   const classes = styles();
   const handleDateChange = (date) => {
      setSelectedDate(date);
      setError(null);
   };
   const rows = [];
   const [teetimeData, setTeetimeData] = useState(rows);
   const handleSelectTeeTime = async () => {
      //lock tee time
      axios
         .post('https://backend.richlandgolfclub.com/api/teetimes/lock', {
            // .post("http://localhost:8080/api/teetimes/lock", {
            teesheetId: teetimeData[selectedID - 1].teesheet_id,
            players: values.players,
            client: values.client,
         })
         .then(() => {
            if (error === '' || error === null) {
               handleStateUpdate(
                  'teetime',
                  moment
                     .utc(teetimeData[selectedID - 1].teetime)
                     .format('h:mm a')
               );
               handleStateUpdate('price', teetimeData[selectedID - 1].price);
               handleStateUpdate(
                  'bookingDate',
                  teetimeData[selectedID - 1].teetime_date
               );
               handleStateUpdate(
                  'teesheetId',
                  teetimeData[selectedID - 1].teesheet_id
               );
               handleStateUpdate(
                  'greenFee',
                  teetimeData[selectedID - 1].green_fee_18
               );
               handleStateUpdate(
                  'cartFee',
                  teetimeData[selectedID - 1].cart_fee_18
               );
               handleStateUpdate('tax', teetimeData[selectedID - 1].tax_18);
               setError('');
               nextStep();
            }
         })
         .catch((err) => {
            setError(
               'Sorry, the teetime you requested is unavailable. Please try again.'
            );
            setShowTeetime(false);
         });
   };

   const handleBookTeeTime = async (teetime, players) => {
      //lock tee time
      axios
         .post('https://backend.richlandgolfclub.com/api/teetimes/lock', {
            teesheetId: teetime.teesheet_id,
            players: players,
            client: values.client,
         })
         .then(() => {
            if (error === '' || error === null) {
               handleStateUpdate('players', players);
               handleStateUpdate(
                  'teetime',
                  moment.utc(teetime.teetime).format('h:mm a')
               );
               handleStateUpdate('price', teetime.price);
               handleStateUpdate('bookingDate', teetime.tee_date);
               handleStateUpdate('teesheetId', teetime.teesheet_id);
               handleStateUpdate('greenFee', teetime.green_fee_18);
               handleStateUpdate('cartFee', teetime.cart_fee_18);
               handleStateUpdate('tax', teetime.tax_18);
               setError('');
               nextStep();
            }
         })
         .catch((err) => {
            console.log(err);
            setError(
               'Sorry, the teetime you requested is unavailable. Please try again.'
            );
            setShowTeetime(false);
         });
   };
   // after form submit validating the form data using validator
   const submitFormData = (e) => {
      e.preventDefault();
   };

   return (
      <React.Fragment>
         <form onSubmit={submitFormData}>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='center'
               direction='row'
            >
               <Grid item>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                     <KeyboardDatePicker
                        label='Please select date'
                        value={selectedDate}
                        onChange={handleDateChange}
                     />
                  </MuiPickersUtilsProvider>
               </Grid>
            </Grid>
            <Fade in={isLoading} unmountOnExit>
               <div style={{ textAlign: 'center' }}>
                  <CircularProgress style={{ margin: '20px' }} />
               </div>
            </Fade>
            <div style={{ textAlign: 'center', marginTop: '5' }}>
               {error !== '' && error !== null ? (
                  <Typography variant='body1' color='error'>
                     {error}
                  </Typography>
               ) : (
                  ''
               )}
            </div>
            {data?.length === 0 && (
               <div style={{ textAlign: 'center', marginTop: '5' }}>
                  <Typography variant='body1' color='error'>
                     {
                        'No tee times available for the date you selected. Please try again.'
                     }
                  </Typography>
               </div>
            )}
            {showTeetime && (
               <Grid
                  container
                  spacing={3}
                  alignItems='center'
                  justifyContent='center'
                  direction='row'
               >
                  {data?.map((teetime) => (
                     <Grid item xs={6} key={teetime.id}>
                        <TeetimeCard
                           key={teetime.id}
                           teetime={teetime}
                           onBook={handleBookTeeTime}
                        />
                     </Grid>
                  ))}
               </Grid>
            )}
         </form>
      </React.Fragment>
   );
}
