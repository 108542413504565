import React, { useState } from "react";

import moment from "moment";

import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import GolfCourseOutlinedIcon from "@material-ui/icons/GolfCourseOutlined";
import Player from "./Player";
import Search from "./Search";
import Review from "./Review";
import Finish from "./Finish";

function Copyright() {
   return (
      <Typography variant="body2" color="textSecondary" align="center">
         {"Copyright © "}
         <Link color="inherit" href="https://www.RichlandGolfClub.com/">
            Richland Golf Club
         </Link>
         {" " + new Date().getFullYear()}
      </Typography>
   );
}

const useStyles = makeStyles((theme) => ({
   appBar: {
      position: "relative",
   },
   layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
         width: 600,
         marginLeft: "auto",
         marginRight: "auto",
      },
   },
   paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
         marginTop: theme.spacing(6),
         marginBottom: theme.spacing(6),
         padding: theme.spacing(3),
      },
   },
   stepper: {
      padding: theme.spacing(3, 0, 5),
   },
   buttons: {
      display: "flex",
      justifyContent: "flex-end",
   },
   button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
   },
   avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
   },
}));

const steps = ["Search", "Player",  "Finish"];

export default function Checkout() {
   const classes = useStyles();

   //state for steps
   const [step, setStep] = useState(0);

   //state for form data
   const [formData, setFormData] = useState({
      client: Math.round(Math.random() * 10000000).toString(),
      bookingDate: moment.utc().local().format("MM/DD/YYYY"),
      teetime: "08:00 AM",
      players: "1",
      mobilePhone: localStorage.getItem('mobilePhone')?localStorage.getItem('mobilePhone'):'',
      email: localStorage.getItem('email')?localStorage.getItem('email'):'',
      player1: localStorage.getItem('player1')?localStorage.getItem('player1'):'',
      player2: "Guest",
      player3: "Guest",
      player4: "Guest",
      confirmation: "",
      teesheetId: "0",
      greenFee: "0",
      cartFee: "0",
      tax: "0",
   });
   // function for going to next step by increasing step state by 1
   const nextStep = () => {
      setStep(step + 1);
   };

   // function for going to previous step by decreasing step state by 1
   const prevStep = () => {
      setStep(step - 1);
   };

   const firstStep = () => {
      setStep(0);
   };

   // handling form input data by taking onchange value and updating our previous form data state
   const handleInputData = (input) => (e) => {
      // input value from the form
      const { value } = e.target;

      //updating for data state taking previous state and then adding new value to create new object
      setFormData((prevState) => ({
         ...prevState,
         [input]: value,
      }));
   };

   const handleStateUpdate = (name, value) => {
      setFormData((prevState) => ({
         ...prevState,
         [name]: value,
      }));
   };

   switch (step) {
      case 0:
         return (
            <React.Fragment>
               <CssBaseline />
               <AppBar
                  position="absolute"
                  color="default"
                  className={classes.appBar}
               >
                  <Toolbar>
                     <Avatar className={classes.avatar}>
                        <GolfCourseOutlinedIcon />
                     </Avatar>
                     <Typography variant="h6" color="inherit" noWrap>
                        Richland Golf Club
                     </Typography>
                  </Toolbar>
               </AppBar>
               <main className={classes.layout}>
                  <Paper className={classes.paper}>
                     <Typography component="h1" variant="h4" align="center">
                        Book Tee Time
                     </Typography>
                     <Stepper activeStep={step} className={classes.stepper}>
                        {steps.map((label) => (
                           <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                           </Step>
                        ))}
                     </Stepper>
                     <Search
                        nextStep={nextStep}
                        handleFormData={handleInputData}
                        values={formData}
                        handleStateUpdate={handleStateUpdate}
                     />
                  </Paper>
                  <Copyright />
               </main>
            </React.Fragment>
         );
      case 1:
         return (
            <React.Fragment>
               <CssBaseline />
               <AppBar
                  position="absolute"
                  color="default"
                  className={classes.appBar}
               >
                  <Toolbar>
                     <Avatar className={classes.avatar}>
                        <GolfCourseOutlinedIcon />
                     </Avatar>
                     <Typography variant="h6" color="inherit" noWrap>
                        Richland Golf Club
                     </Typography>
                  </Toolbar>
               </AppBar>
               <main className={classes.layout}>
                  <Paper className={classes.paper}>
                     <Typography component="h1" variant="h4" align="center">
                        Book Tee Time
                     </Typography>
                     <Stepper activeStep={step} className={classes.stepper}>
                        {steps.map((label) => (
                           <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                           </Step>
                        ))}
                     </Stepper>
                     <Player
                        nextStep={nextStep}
                        prevStep={prevStep}
                        handleFormData={handleInputData}
                        values={formData}
                        handleStateUpdate={handleStateUpdate}
                        firstStep={firstStep}
                     />
                  </Paper>
                  <Copyright />
               </main>
            </React.Fragment>
         );
         
      case 2:
         return (
            <React.Fragment>
               <CssBaseline />
               <AppBar
                  position="absolute"
                  color="default"
                  className={classes.appBar}
               >
                  <Toolbar>
                     <Avatar className={classes.avatar}>
                        <GolfCourseOutlinedIcon />
                     </Avatar>
                     <Typography variant="h6" color="inherit" noWrap>
                        Richland Golf Club
                     </Typography>
                  </Toolbar>
               </AppBar>
               <main className={classes.layout}>
                  <Paper className={classes.paper}>
                     <Typography component="h1" variant="h4" align="center">
                        Book Tee Time
                     </Typography>
                     <Stepper activeStep={step} className={classes.stepper}>
                        {steps.map((label) => (
                           <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                           </Step>
                        ))}
                     </Stepper>
                     <Review
                        prevStep={prevStep}
                        nextStep={nextStep}
                        values={formData}
                        handleStateUpdate={handleStateUpdate}
                        firstStep={firstStep}
                     />
                  </Paper>
                  <Copyright />
               </main>
            </React.Fragment>
         );
      case 3:
         return (
            <React.Fragment>
               <CssBaseline />
               <AppBar
                  position="absolute"
                  color="default"
                  className={classes.appBar}
               >
                  <Toolbar>
                     <Avatar className={classes.avatar}>
                        <GolfCourseOutlinedIcon />
                     </Avatar>
                     <Typography variant="h6" color="inherit" noWrap>
                        Richland Golf Club
                     </Typography>
                  </Toolbar>
               </AppBar>
               <main className={classes.layout}>
                  <Paper className={classes.paper}>
                     <Typography component="h1" variant="h4" align="center">
                        Book Tee Time
                     </Typography>
                     <Stepper activeStep={step} className={classes.stepper}>
                        {steps.map((label) => (
                           <Step key={label}>
                              <StepLabel>{label}</StepLabel>
                           </Step>
                        ))}
                     </Stepper>
                     <Finish values={formData} step={step} setStep={setStep} />
                  </Paper>
                  <Copyright />
               </main>
            </React.Fragment>
         );
      default:
         <div></div>;
   }
}
