import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { FormikProvider } from 'formik';
import axios from 'axios';
import { useQueryClient } from 'react-query';

const Player = ({
   firstStep,
   nextStep,
   prevStep,
   handleFormData,
   values,
   handleStateUpdate,
}) => {
   const players = parseInt(values.players);
   const queryClient = useQueryClient();
   const [formValues, setFormValues] = useState(values);
   const [formErrors, setFormErrors] = useState({});
   const [isSubmit, setIsSubmit] = useState(false);

   const handleSubmit = (e) => {
      e.preventDefault();
      setFormErrors(validate(formValues));
      if (players >= 2 && (!values.player2 || formValues.player2 === '')) {
         formValues.player2 = 'Guest';
      }
      if (players >= 3 && (!values.player3 || formValues.player3 === '')) {
         formValues.player3 = 'Guest';
      }
      if (players >= 4 && (!values.player4 || formValues.player4 === '')) {
         formValues.player4 = 'Guest';
      }
      console.log(formValues);
      localStorage.setItem('mobilePhone', formValues.mobilePhone);
      localStorage.setItem('email', formValues.email);
      localStorage.setItem('player1', formValues.player1);
      setIsSubmit(true);
   };

   const handleChange = (e) => {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
   };

   const validate = (values) => {
      const errors = {};
      const regex_email = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      const regex_phone =
         /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
      if (!values.player1) {
         errors.player1 = 'Required';
      }
      // if (players >= 2 && !values.player2) {
      //    errors.player2 = 'Required';
      // }
      // if (players >= 3 && !values.player3) {
      //    errors.player3 = 'Required';
      // }
      // if (players === 4 && !values.player4) {
      //    errors.player4 = 'Required';
      // }
      if (!values.mobilePhone) {
         errors.mobilePhone = 'Required';
      }
      if (!regex_phone.test(values.mobilePhone)) {
         errors.mobilePhone = 'Phone number is not valid';
      }

      // if (!values.email) {
      //    errors.email = 'Required';
      // } else if (!regex_email.test(values.email)) {
      //    errors.email = 'Invalid email address';
      // }

      return errors;
   };

   const Previous = (e) => {
      e.preventDefault();
      axios
         .post('https://backend.richlandgolfclub.com/api/teetimes/unlock', {
            client: values.client,
         })
         .then(() => {
            queryClient.invalidateQueries('inventory').then(() => {
               firstStep();
            });
         })
         .catch((err) => {
            console.log(err);
            // setError(
            //    'Sorry, the teetime you requested is unavailable. Please try again.'
            // );
            // setShowTeetime(false);
         });
   };
   useEffect(() => {
      if (Object.keys(formErrors).length === 0 && isSubmit) {
         let phone = formValues.mobilePhone;
         phone = phone.replace(/\s/g, '');
         phone = phone.replace(/-/g, '');
         phone = phone.replace(/\(/g, '');
         phone = phone.replace(/\)/g, '');
         handleStateUpdate('mobilePhone', phone);
         handleStateUpdate('email', formValues.email);
         handleStateUpdate('player1', formValues.player1);
         handleStateUpdate('player2', formValues.player2);
         handleStateUpdate('player3', formValues.player3);
         handleStateUpdate('player4', formValues.player4);
         nextStep();
      }
   }, [formErrors]);
   return (
      <React.Fragment>
         <form onSubmit={handleSubmit} noValidate>
            <Grid
               container
               spacing={3}
               alignItems='center'
               justifyContent='center'
               direction='row'
            >
               <Grid item xs={6}>
                  <TextField
                     id='mobilePhone'
                     name='mobilePhone'
                     label='Mobile Phone'
                     fullWidth
                     value={formValues.mobilePhone}
                     onChange={handleChange}
                     helperText={formErrors.mobilePhone}
                     placeholder='555-555-5555'
                     required
                  />
               </Grid>
               <Grid item xs={6}>
                  <TextField
                     id='email'
                     name='email'
                     label='Email'
                     fullWidth
                     value={formValues.email}
                     onChange={handleChange}
                     helperText={formErrors.email}
                  />
               </Grid>

               <Grid item xs={6}>
                  <TextField
                     id='player1'
                     name='player1'
                     label='Player 1'
                     fullWidth
                     value={formValues.player1}
                     onChange={handleChange}
                     required
                     helperText={formErrors.player1}
                  />
               </Grid>
               <Grid
                  item
                  xs={6}
                  style={{ display: players >= 2 ? 'block' : 'none' }}
               >
                  <TextField
                     id='player2'
                     name='player2'
                     label='Player 2'
                     fullWidth
                     value={formValues.player2}
                     onChange={handleChange}
                     helperText={formErrors.player2}
                  />
               </Grid>
               <Grid
                  item
                  xs={6}
                  style={{ display: players >= 3 ? 'block' : 'none' }}
               >
                  <TextField
                     id='player3'
                     name='player3'
                     label='Player 3'
                     fullWidth
                     value={formValues.player3}
                     onChange={handleChange}
                     helperText={formErrors.player3}
                  />
               </Grid>
               <Grid
                  item
                  xs={6}
                  style={{ display: players >= 4 ? 'block' : 'none' }}
               >
                  <TextField
                     id='player4'
                     name='player4'
                     label='Player 4'
                     fullWidth
                     value={formValues.player4}
                     onChange={handleChange}
                     helperText={formErrors.player4}
                  />
               </Grid>
               <Grid
                  container
                  spacing={10}
                  alignItems='flex-end'
                  direction='row'
                  justifyContent='flex-end'
               >
                  <Grid item xs={1}>
                     <Button onClick={Previous}>Back</Button>
                  </Grid>
                  <Grid item xs={4}>
                     <Button type='submit' variant='contained' color='primary'>
                        Next
                     </Button>
                  </Grid>
               </Grid>
            </Grid>
         </form>
      </React.Fragment>
   );
};

export default Player;
