import React from 'react';
import { Routes, Route } from 'react-router-dom';

import BookTeeTime from './component/BookTeeTime';


const AppRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<BookTeeTime />} />
           
        </Routes>
    );
}

export default AppRoute