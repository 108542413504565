import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ScheduleIcon from '@material-ui/icons/Schedule';
import PeopleIcon from '@material-ui/icons/People';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import EmailIcon from '@material-ui/icons/Email';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
   content: {
      backgroundColor: theme.palette.background.paper,
   },
}));
const qs = require('qs');

function getPlayers(values) {
   let result = '';
   switch (parseInt(values.players)) {
      case 1:
         result = values.player1;
         break;
      case 2:
         result = values.player1 + ',' + values.player2;
         break;
      case 3:
         result = values.player1 + ',' + values.player2 + ',' + values.player3;
         break;
      case 4:
         result =
            values.player1 +
            ',' +
            values.player2 +
            ',' +
            values.player3 +
            ',' +
            values.player4;
         break;
      default:
         result = '';
   }
   return result;
}

export default function Review({
   prevStep,
   nextStep,
   values,
   handleStateUpdate,
   firstStep,
}) {
   const classes = useStyles();

   const [error, setError] = useState(null);

   const Previous = (e) => {
      e.preventDefault();
      firstStep();
   };

   useEffect(() => {
      if (values.teetime === '') {
         firstStep();
      }
   }, []);

   const submitData = () => {
      let payload = {
         bookingDate: values.bookingDate,
         teetime: values.teetime,
         mobilePhone: values.mobilePhone,
         email: values.email,
         player1: values.player1,
         player2: values.player2,
         player3: values.player3,
         player4: values.player4,
         price: values.price,
         players: values.players,
         sendSMS: true,
         teesheetId: values.teesheetId,
         greenFee: values.greenFee,
         cartFee: values.cartFee,
         tax: values.tax,
         source: 2, //www2
         client: values.client,
      };
      let pl = qs.stringify(payload);
      axios
         .post(
            'https://backend.richlandgolfclub.com/api/teetimes/bookteetime2',
            pl
         )
         // .post("http://localhost:8080/api/teetimes/bookteetime2", pl)
         .then(function (response) {
            let obj = response.data;
            if (obj.confirmationNumber !== null)
               handleStateUpdate('confirmation', obj.confirmationNumber);
            else handleStateUpdate('confirmation', 'Error');
            nextStep();
         })
         .catch((error) => {
            console.error(error);
            setError(
               'Sorry, the teetime you requested is unavailable. Please try again.'
            );
         });
   };

   return (
      <React.Fragment>
         <Grid
            container
            spacing={3}
            alignItems='center'
            justifyContent='flex-start'
            direction='column'
         >
            <Grid item xs={12} md={6}>
               <Typography variant='h6' className={classes.title}>
                  Please review your booking
               </Typography>
               <div className={classes.content}>
                  <List dense={false}>
                     <ListItem>
                        <ListItemAvatar>
                           <ScheduleIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText
                           primary={values.bookingDate + ' ' + values.teetime}
                        />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <PeopleIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={getPlayers(values)} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <PhoneIphoneIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={values.mobilePhone} />
                     </ListItem>
                     <ListItem>
                        <ListItemAvatar>
                           <EmailIcon color='primary' />
                        </ListItemAvatar>
                        <ListItemText primary={values.email} />
                     </ListItem>
                  </List>
               </div>
            </Grid>
         </Grid>
         {error !== '' && error !== null ? (
            <Typography variant='body1' color='error'>
               {error}
            </Typography>
         ) : (
            ''
         )}
         <Typography variant='body1' color='error'>
            {
               "Please press the 'Book Now' button to confirm your booking. You will receive a confirmation text message."
            }
         </Typography>
         <Grid
            container
            spacing={10}
            alignItems='flex-end'
            direction='row'
            justifyContent='flex-end'
         >
            <Grid item xs={2}>
               <Button onClick={Previous}>Back</Button>
            </Grid>
            <Grid item xs={6}>
               <Button
                  type='submit'
                  variant='contained'
                  color='primary'
                  onClick={() => submitData()}
               >
                  BOOK NOW
               </Button>
            </Grid>
         </Grid>
      </React.Fragment>
   );
}
