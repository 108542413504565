export function formatMoney(number) {
   if (number) {
      return number.toLocaleString("en-US", {
         style: "currency",
         currency: "USD",
      });
   } else {
      return "$0.00";
   }
}
