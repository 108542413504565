import axios from 'axios';
import { useQuery } from 'react-query';
import moment from 'moment';

const fetchTeetimeInventory = async (date, start, end, players) => {
   let sdate = moment(date.toLocaleString('en-US')).format('YYYY-MM-DD');

   const { data } = await axios.get(
      'https://backend.richlandgolfclub.com' +
         `/api/teetimes/inventory_by_time?date=${sdate}&start=${start}&end=${end}&players=${players}`
   );

   return data;
};

export function useTeetimeInventory(date, start, end, players) {
   return useQuery(['inventory', date, players, start, end], () =>
      fetchTeetimeInventory(date, start, end, players)
   );
}